import type { MicroServicesBaseUrls } from "@meeting-doctors/api";

export type environment = {
    microservicesUrls: MicroServicesBaseUrls;
};

let environment: environment;

export const getEnvironment = async (): Promise<environment> => {
    const envPath = "/env.json";
    const envUrl = new URL(envPath, location.href).href;
    environment = await (await fetch(envUrl)).json();
    return environment;
};

export { environment };
