import { QueryClientProvider } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { Suspense, lazy } from "react";

import { getEnvironment } from "@/environment/environment";
import { queryClient } from "./query-client";

/* import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'



const persister = createSyncStoragePersister({
    storage: window.localStorage,
}) */

/* const getEnv = async () => {
    const res = (await fetch("/env.json")).json();
    return res;
}; */

export const ReactQueryDevtools =
    process.env.NODE_ENV === "production"
        ? () => null // Render nothing in production
        : lazy(() =>
              // Lazy load in development
              import("@tanstack/react-query-devtools").then((res) => ({
                  default: res.ReactQueryDevtools,
              })),
          );

const SuspenseMainRouter = lazy(() =>
    import("@/modules/main/main.route").then((module) => ({
        default: module.MainRouter,
    })),
);

export const MainQuery = () => {
    const { data } = useQuery({ queryKey: ["env"], queryFn: getEnvironment });

    if (!data) {
        return null;
    }

    return (
        <Suspense>
            <SuspenseMainRouter />
        </Suspense>
    );
};

export const Main = () => {
    /* return (
        <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{ persister }}
        >
            <ReactQueryDevtools />
            <MainQuery />
        </PersistQueryClientProvider>
    ); */
    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools />
            <MainQuery />
        </QueryClientProvider>
    );
};
